var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "padding-bottom": "16px",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "background-color": ['#FFF', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "width": "100%",
      "max-width": "1700px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": ['0px', '85px']
    }
  }, [_c('c-box', {
    attrs: {
      "pt": "20px",
      "pb": "10px",
      "justify-content": "center",
      "d": ['none', 'flex']
    }
  }, [_c('c-box', {
    attrs: {
      "font-size": ['18px', '28px'],
      "font-weight": "bold"
    }
  }, [_vm._v(" Notifikasi ")])], 1), _c('c-box', {
    attrs: {
      "as": "hr",
      "w": "100%",
      "border-top": "1px solid #0000001A"
    }
  }), _c('NotificationItem', {
    staticClass: "icon-notif",
    staticStyle: {
      "max-width": "none"
    },
    attrs: {
      "notifications": _vm.fetchPopupNotification.data.value,
      "ellipsis": false
    },
    on: {
      "onClick": _vm.handleClickNotification
    }
  })], 1), _c('ModalInfo', {
    attrs: {
      "for": "infoNotitifcation",
      "close-on-overlay-click": false,
      "is-open": _vm.isOpenModal,
      "image": _vm.modalNotification.image,
      "text": _vm.modalNotification.text,
      "button-text": _vm.modalNotification.buttonText,
      "button-link": _vm.modalNotification.buttonLink,
      "with-button-close": _vm.modalNotification.buttonClose
    },
    on: {
      "close": _vm.handleCloseSuccessModal
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }